<template>
  <v-layout column wrap>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF GADGET LOANS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-flex xs12 class="mx-2">
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4>
            <v-select
              v-model="category"
              class="mx-3 pt-6"
              :items="['Yearly','Monthly']"
              label="Category"
              required
              dense
              @change="get_loan_approved_items"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 class="mx-2 pt-4">
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4>
            <v-select
              v-if="category==='Yearly'"
              v-model="year_of"
              class="mx-3 pt-5"
              :items="year_of_items"
              item-value="year"
              item-text="year"
              label="Year Of"
              required
              dense
              @change="get_loan_approved_items"
            ></v-select>
            <v-select
              v-if="category==='Monthly'"
              v-model="month_of"
              class="mx-3 pt-5"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              label="Month Of"
              required
              dense
              @change="get_loan_approved_items"
            ></v-select>
            <v-select
              v-model="purpose_of_loan"
              class="mx-3  pt-5"
              :items="['All','Laptop','Cellphone']"
              item-value="id"
              label="Loan Purpose"
              required
              dense
              @change="get_loan_approved_items"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
                        <span class="headline" v-if="can_print">
                                                Print
                                                <v-icon
                                                  class="mx-3"
                                                  color="warning"
                                                  @click="print_loan_approved_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                        </span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-data-table
        :headers="headers"
        :items="list_of_loan_approved"
        :search="search"
        :loading="data_table_loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <span class="subtitle-1">No:  {{total_count}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="subtitle-1">Amount PHP:  {{formatPrice(total_amount)}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="subtitle-1">Interest PHP:  {{formatPrice(total_interest)}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="subtitle-1">Total PHP:  {{formatPrice(total_loans)}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="subtitle-1">Total Bal. PHP:  {{formatPrice(total_balance)}}</span>
            <v-spacer></v-spacer>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearchOutline"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr
          >
            <td>
              {{ item.loans.laf_no }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.loans.type_of_loan }}
            </td>
            <td>
              {{ item.loans.date_accomplished }}
            </td>
            <td>
              {{ item.loans.mode_of_loan }}
            </td>
            <td>
              {{ item.loans.terms }}
            </td>
            <td>
              {{ formatPrice(item.loans.amount) }}
            </td>
            <td>
              {{ formatPrice(item.loans.interest) }}
            </td>
            <td>
              {{ formatPrice(item.loans.total) }}
            </td>
            <td>
              {{ formatPrice(item.loans.balance) }}
            </td>
            <td>
              {{ formatPrice(item.loans.amount_due) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import moment from "moment";
  import {mdiAccountSearchOutline, mdiPrinter} from '@mdi/js'

  export default {
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        total_balance: 0,
        total_count: 0,
        purpose_of_loan: '',
        selected_month_of: 'NA',
        category: '',
        month_of: '',
        year_of_items: [],
        year_of: '',
        list_of_loan_approved: [],
        month_of_items: [],
        total_amount: 0,
        total_interest: 0,
        total_loans: 0,
        can_print: false,
        //table props
        search: '',
        data_table_loading: false,
        headers: [
          {text: 'Laf #', value: 'loans.laf_no'},
          {text: 'Name', value: 'name'},
          {text: 'Type', value: 'loans.type_of_gadget'},
          {text: 'Date Released', value: 'loans.date_accomplished'},
          {text: 'Mode', value: 'loans.mode_of_loan'},
          {text: 'Terms', value: 'loans.terms'},
          {text: 'Amount', value: 'loans.amount'},
          {text: 'Interest', value: 'loans.interest'},
          {text: 'Total', value: 'loans.total'},
          {text: 'Balance', value: 'loans.total'},
          {text: 'Due Amount', value: 'loans.amount_due'},
        ],
      }
    },
    mounted() {
      const data = new FormData()
      data.append('branch_id', this.branch_id);
      this.retrieve_transaction_month(data)
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
      this.retrieve_all_transaction_year()
        .then((response) => {
          this.year_of_items = response.data
          this.year_of_items.splice(0, 0, {
            id: this.year_of_items[0].year + 1,
            year: 'All'
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id','branch', 'branch_address', 'branch_contact_no']),
    },
    watch: {
      branch_id: function () {
        this.can_print = false
        this.month_of = ''
        this.list_of_loan_approved = []
        this.total_amount = '0'
        this.total_interest = '0'
        this.total_loans = '0'
      }
    },
    methods: {
      ...mapActions('transaction_months', ['retrieve_transaction_month', 'retrieve_all_transaction_year']),
      ...mapActions('gadget_loans_data', ['list_of_gadget_loans']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      get_loan_approved_items() {
        if (this.category === 'Yearly') {
          if (this.year_of_items.length > 0) {
            var indexs = this.year_of_items.map(function (x) {
              return x.year
            }).indexOf(this.year_of)
            if (indexs != -1) {
              this.selected_month_of = this.year_of_items[indexs].year;
            }
          }
        } else {
          if (this.month_of_items.length > 0) {
            var index = this.month_of_items.map(function (x) {
              return x.id
            }).indexOf(this.month_of)
            if (index != -1) {
              this.selected_month_of = this.month_of_items[index].month_of;
            }
          }
        }
        if (this.purpose_of_loan === 'All') {
          this.purpose_of_loan = ''
        }
        if (this.selected_month_of != 'NAN') {
          if (this.selected_month_of === 'All') {
            this.selected_month_of = ''
          }
          this.data_table_loading = true
          const data = new FormData()
          data.append('month_of', this.selected_month_of);
          data.append('branch_id', this.branch_id);
          data.append('purpose_of_loan', this.purpose_of_loan);
          this.list_of_gadget_loans(data)
            .then((response) => {
              this.can_print = true
              this.list_of_loan_approved = response.data[0].loans
              this.total_count = this.list_of_loan_approved.length
              this.total_balance = response.data[0].total_balance
              this.total_amount = response.data[0].total_amount
              this.total_interest = response.data[0].total_interest
              this.total_loans = response.data[0].total
              this.data_table_loading = false
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      print_loan_approved_data() {
        var imgData = this.company_logo
        var payments_array = []
        var indexMonth = this.month_of_items.map(function (x) {
          return x.id
        }).indexOf(this.month_of)

        var month = this.month_of_items[indexMonth].month_of
        payments_array.push(
          [
            {text: 'ID', alignment: 'center', style: 'label'},
            {text: 'Loan App. No', alignment: 'center', style: 'label'},
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Type', alignment: 'center', style: 'label'},
            {text: 'Date Released', alignment: 'center', style: 'label'},
            {text: 'Mode', alignment: 'center', style: 'label'},
            {text: 'Terms', alignment: 'center', style: 'label'},
            {text: 'Amount', alignment: 'center', style: 'label'},
            {text: 'Interest', alignment: 'center', style: 'label'},
            {text: 'Total', alignment: 'center', style: 'label'},
            {text: 'Balance', alignment: 'center', style: 'label'},
            {text: 'Due Amount', alignment: 'center', style: 'label'},
          ]
        )
        if (this.list_of_loan_approved.length > 0) {
          var total_amount = 0
          var total_interest = 0
          var total_total = 0
          this.list_of_loan_approved.forEach(function (item, index, loan) {
            total_amount = total_amount + parseFloat(item.loans.amount)
            total_interest = total_interest + parseFloat(item.loans.interest)
            total_total = total_total + parseFloat(item.loans.total)

            payments_array.push(
              [
                {text: item.id, alignment: 'center'},
                {text: item.loans.laf_no, alignment: 'left'},
                {text: item.name, alignment: 'left'},
                {text: item.loans.type_of_gadget, alignment: 'center'},
                {text: item.loans.date_accomplished, alignment: 'center'},
                {text: item.loans.mode_of_loan, alignment: 'center'},
                {text: item.loans.terms, alignment: 'center'},
                {
                  text: (item.loans.amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'center'
                },
                {
                  text: (item.loans.interest / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'center'
                },
                {
                  text: (item.loans.total / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'center'
                },
                {
                  text: (item.loans.balance / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'center'
                },
                {
                  text: (item.loans.amount_due / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'center'
                },
              ]
            )
            if (Object.is(loan.length - 1, index)) {
              // execute last item logic
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Total: ',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    color: 'red',
                    text: (total_amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                  {
                    color: 'red',
                    text: (total_interest / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                  {
                    color: 'red',
                    text: (total_total / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                ]
              )
            }
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'landscape',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF GADGET LOANS APPROVED', style: 'title'},
            '============================================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Month of: ' + month, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [30, 50, 90, 40, 70, 40, 30, 50, 50, 50, 50, 50],
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Collector', style: 'aa_placeholder'},
                    {text: 'Cashier', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'cs_for'},
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
